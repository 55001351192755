import React from "react"
import { Link } from "gatsby"

import { useFirebase } from "gatsby-plugin-firebase"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"
import "./release-notes.css"

const ReleaseNotesPage = () => {
    
    useFirebase(firebase => { firebase.analytics().logEvent("page_view_release_notes") }, [])

    return (
    <Layout>
        <SEO title="apocha" />
        <Navigation />
        <ReleaseNotesContainer>
          <ReleaseSectionHeading>apocha Versionshistorie</ReleaseSectionHeading>
          <p>
            apocha is being actively developed. An update with new functions is published approximately every two weeks.
            If you are missing a useful function or if you have general suggestions, comments or questions, please
            let me know by sending me a short email to <a href="mailto:support@apocha.app">support@apocha.app</a>.
          </p>
          <ReleaseSection>
            <ReleaseVersion>2021.04.0</ReleaseVersion>
            <ReleaseDate>January 31st, 2021</ReleaseDate>
            <ReleaseDescription>
              <dl>
                <FeatureHeading>New Features</FeatureHeading>
                <dd>
                  <ul>
                    <li>Retailermap with selection of start and end date</li>
                    <li>Pie chart for expenses by retailer</li>
                  </ul>
                </dd>
              </dl>
            </ReleaseDescription>
          </ReleaseSection>
          <ReleaseSection>
            <ReleaseVersion>2021.02.1</ReleaseVersion>
            <ReleaseDate>January 18th, 2021</ReleaseDate>
            <ReleaseDescription>
              <dl>
                <BugFixHeading>Bug Fixes</BugFixHeading>
                <dd>
                  <ul>
                    <li>Some receipts have been imported twice</li>
                  </ul>
                </dd>
              </dl>
            </ReleaseDescription>
          </ReleaseSection>
          <ReleaseSection>
            <ReleaseVersion>2021.02.0</ReleaseVersion>
            <ReleaseDate>January 16th, 2021</ReleaseDate>
            <ReleaseDescription>
              <dl>
                <FeatureHeading>New Features</FeatureHeading>
                <dd>
                  <ul>
                    <li>Improved user experience for taking a picture of a receipt</li>
                  </ul>
                </dd>
              </dl>
            </ReleaseDescription>
          </ReleaseSection>
          <ReleaseSection>
            <ReleaseVersion>2021.01.0</ReleaseVersion>
            <ReleaseDate>January 10th, 2021</ReleaseDate>
            <ReleaseDescription>
              <dl>
                <FeatureHeading>New Features</FeatureHeading>
                <dd>
                  <ul>
                    <li>You can now set a default category for all line items of a receipt.</li>
                  </ul>
                </dd>
              </dl>
            </ReleaseDescription>
          </ReleaseSection>
          <ReleaseSection>
            <ReleaseVersion>prior to 2021.01.0</ReleaseVersion>
            <ReleaseDate>January 10th, 2021</ReleaseDate>
            <ReleaseDescription>
              <dl>
                <FeatureHeading>New Features and Bug Fixes</FeatureHeading>
                <dd>
                  <ul>
                    <li>Older entries are available <ExternalLink href="https://apocha.de/release-notes/" target="_blank">in German only</ExternalLink>.</li>
                  </ul>
                </dd>
              </dl>
            </ReleaseDescription>
          </ReleaseSection>
        </ReleaseNotesContainer>
        <Footer />
    </Layout>
)
}

export default ReleaseNotesPage

const ReleaseNotesContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
const ReleaseSectionHeading = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const ReleaseSection = styled.dl`
	border-radius: 5px;
	box-shadow: 0 0 1px 1px rgba(31,45,61,.15);
	padding: 20px 25px 0px 25px;
	margin-bottom: 20px;
`

const ReleaseVersion = styled.dt`
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.secondary};
  margin-bottom: 10px;
	border-bottom: 1px solid #cccccc; 
`
const ReleaseDate = styled.dt`
  ${props => props.theme.font_size.xxsmall};
  color: ${props => props.theme.color.primary};
  margin-bottom: 10px;
`

const ReleaseDescription = styled.dd`
  line-height: 22px;
  margin-bottom: 25px;
`

const FeatureHeading = styled.dt`
	${props => props.theme.font_size.default};
	&::before {
		content: url('https://storage.googleapis.com/apocha-public/icons/icon_stars_new_0024.png');
		margin-right: 5px;
		vertical-align: middle;
	}
`

const BugFixHeading = styled.dt`
	${props => props.theme.font_size.default};
	&::before {
		content: url('https://storage.googleapis.com/apocha-public/icons/icon_wench_0024.png');
		margin-right: 5px;
		vertical-align: middle;
	}
`

const ExternalLink = styled.a`
  align-items: center;
  padding-bottom: 1px;
`

